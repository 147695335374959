<template>
	<v-row no-gutters>
		<v-col cols="12">
			<v-tabs v-model="tab" grow>
				<v-tab>Acompanhamento de Vendas</v-tab>
				<v-tab>Acompanhamento Gerencial</v-tab>
			</v-tabs>

			<v-tabs-items v-model="tab">
				<v-tab-item>
					<v-card flat>
						<v-card-text>
							<iframe
								title="Relatorio_Vendas_ApoioDecisao_V2.0"
								style="height:80vh; width:100%"
								src="https://app.powerbi.com/reportEmbed?reportId=3e4609f2-5978-4ba9-8f48-7eae54be9bb8&autoAuth=true&ctid=afc298ce-5dba-40e7-a35b-d0e741c600a5"
								frameborder="0"
								allowfullscreen="true"
							></iframe>
						</v-card-text>
					</v-card>
				</v-tab-item>
				<v-tab-item>
					<v-card flat>
						<v-card-text>
							<iframe
								title="Relatrio_Gerencial_Apoio_Decisao_Vendas"
								style="height:80vh; width:100%"
								src="https://app.powerbi.com/reportEmbed?reportId=b8b87b5a-0e01-4e91-b0a3-c6f92fa87aa2&autoAuth=true&ctid=afc298ce-5dba-40e7-a35b-d0e741c600a5"
								frameborder="0"
								allowfullscreen="true"
							></iframe>
						</v-card-text>
					</v-card>
				</v-tab-item>
			</v-tabs-items>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: "TiBI",
	components: {},
	data: () => ({
		tab: 0,
	}),
	computed: {},
	methods: {
		async init() {},
	},
	created() {
		this.init();
	},
};
</script>